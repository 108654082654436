.carousel  .d-none {
  display: unset !IMPORTANT;
}
.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  background-image: url(http://localhost:3000/static/media/arrow_left.db6e2d8….svg);
  border: 1px solid;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50px;
}

.news .news-pagination {
    margin-top: 30px;
    color: #838893;
  }
  
  .news .news-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .news .news-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .news .news-pagination li a {
    color: #000000;
    border: 1px solid #059473;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .news .news-pagination li.active,
  .news .news-pagination li:hover {
    background: transparent;
    color: #fff;
  }
  
  .news .news-pagination li.active a,
  .news .news-pagination li:hover a {
    color: #000000;
  }
  
  /*--------------------------------------------------------------
  # news Posts List
  --------------------------------------------------------------*/
  .news .posts-list .post-item {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
    transition: 0.3s;
  }
  
  .news .posts-list .post-img img {
    transition: 0.5s;
  }
  
  .news .posts-list .post-date {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #059473;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    padding: 6px 12px;
    font-weight: 500;
  }
  
  .news .posts-list .post-content {
    padding: 30px;
  }
  
  .news .posts-list .post-title {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
    transition: 0.3s;
    margin-bottom: 15px;
  }
  
  .news .posts-list .meta svg {
    font-size: 16px;
    color: #ff5e14;
  }
  
  .news .posts-list .meta span {
    font-size: 15px;
    color: #838893;
  }
  
  .news .posts-list p {
    margin-top: 20px;
  }
  
  .news .posts-list hr {
    color: #888;
    margin-bottom: 20px;
  }
  
  .news .posts-list .readmore {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    transition: 0.3s;
    color: #838893;
  }
  
  .news .posts-list .readmore i {
    line-height: 0;
    margin-left: 6px;
    font-size: 16px;
  }
  
  .news .posts-list .post-item:hover .post-title,
  .news .posts-list .post-item:hover .readmore {
    color: #059473;
  }
  
  .news .posts-list .post-item:hover .post-img img {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # news Details
  --------------------------------------------------------------*/
  .news .news-details {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .news .news-details .post-img {
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .news .news-details .title {
    font-size: 28px;
    font-weight: 700;
    padding: 0;
    margin: 20px 0 0 0;
    color: var(--color-secondary);
  }
  
  .news .news-details .content {
    margin-top: 20px;
  }
  
  .news .news-details .content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .news .news-details .content blockquote {
    overflow: hidden;
    background-color: rgba(82, 86, 94, 0.06);
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .news .news-details .content blockquote p {
    color: var(--color-default);
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .news .news-details .content blockquote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--color-primary);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .news .news-details .meta-top {
    margin-top: 20px;
    color: #6c757d;
  }
  
  .news .news-details .meta-top ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .news .news-details .meta-top ul li+li {
    padding-left: 20px;
  }
  
  .news .news-details .meta-top i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
    color: var(--color-primary);
  }
  
  .news .news-details .meta-top a {
    color: #6c757d;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    transition: 0.3s;
  }
  
  .news .news-details .meta-top a:hover {
    color: var(--color-primary);
  }
  
  .news .news-details .meta-bottom {
    padding-top: 10px;
    border-top: 1px solid rgba(82, 86, 94, 0.15);
  }
  
  .news .news-details .meta-bottom i {
    color: #838893;
    display: inline;
  }
  
  .news .news-details .meta-bottom a {
    color: rgba(82, 86, 94, 0.8);
    transition: 0.3s;
  }
  
  .news .news-details .meta-bottom a:hover {
    color: var(--color-primary);
  }
  
  .news .news-details .meta-bottom .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .news .news-details .meta-bottom .cats li {
    display: inline-block;
  }
  
  .news .news-details .meta-bottom .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .news .news-details .meta-bottom .tags li {
    display: inline-block;
  }
  
  .news .news-details .meta-bottom .tags li+li::before {
    padding-right: 6px;
    color: var(--color-default);
    content: ",";
  }
  
  .news .news-details .meta-bottom .share {
    font-size: 16px;
  }
  
  .news .news-details .meta-bottom .share i {
    padding-left: 5px;
  }
  
  .news .post-author {
    padding: 20px;
    margin-top: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .news .post-author img {
    max-width: 120px;
    margin-right: 20px;
  }
  
  .news .post-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: var(--color-secondary);
  }
  
  .news .post-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .news .post-author .social-links a {
    color: rgba(82, 86, 94, 0.5);
    margin-right: 5px;
  }
  
  .news .post-author p {
    font-style: italic;
    color: rgba(108, 117, 125, 0.8);
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # news Sidebar
  --------------------------------------------------------------*/
  .news .sidebar {
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .news .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    color: var(--color-secondary);
  }
  
  .news .sidebar .sidebar-item+.sidebar-item {
    margin-top: 40px;
  }
  
  .news .sidebar .search-form form {
    background: #fff;
    border: 1px solid rgba(82, 86, 94, 0.3);
    padding: 3px 10px;
    position: relative;
  }
  
  .news .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
  }
  
  .news .sidebar .search-form form input[type=text]:focus {
    outline: none;
  }
  
  .news .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: var(--color-primary);
    color: var(--color-secondary);
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
  }
  
  .news .sidebar .search-form form button i {
    line-height: 0;
  }
  
  .news .sidebar .search-form form button:hover {
    background: rgba(254, 185, 0, 0.8);
  }
  
  .news .sidebar .categories ul {
    list-style: none;
    padding: 0;
  }
  
  .news .sidebar .categories ul li+li {
    padding-top: 10px;
  }
  
  .news .sidebar .categories ul a {
    color: var(--color-secondary);
    transition: 0.3s;
  }
  
  .news .sidebar .categories ul a:hover {
    color: var(--color-default);
  }
  
  .news .sidebar .categories ul a span {
    padding-left: 5px;
    color: rgba(54, 77, 89, 0.4);
    font-size: 14px;
  }
  
  .news .sidebar .recent-posts .post-item {
    display: flex;
  }
  
  .news .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
  }
  
  .news .sidebar .recent-posts img {
    max-width: 80px;
    margin-right: 15px;
  }
  
  .news .sidebar .recent-posts h4 {
    font-size: 15px;
    font-weight: bold;
  }
  
  .news .sidebar .recent-posts h4 a {
    color: var(--color-secondary);
    transition: 0.3s;
  }
  
  .news .sidebar .recent-posts h4 a:hover {
    color: var(--color-primary);
  }
  
  .news .sidebar .recent-posts time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: rgba(54, 77, 89, 0.4);
  }
  
  .news .sidebar .tags {
    margin-bottom: -10px;
  }
  
  .news .sidebar .tags ul {
    list-style: none;
    padding: 0;
  }
  
  .news .sidebar .tags ul li {
    display: inline-block;
  }
  
  .news .sidebar .tags ul a {
    color: #838893;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid rgba(131, 136, 147, 0.4);
    display: inline-block;
    transition: 0.3s;
  }
  
  .news .sidebar .tags ul a:hover {
    color: var(--color-secondary);
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
  }
  
  .news .sidebar .tags ul a span {
    padding-left: 5px;
    color: rgba(131, 136, 147, 0.8);
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # news Comments
  --------------------------------------------------------------*/
  .news .comments {
    margin-top: 30px;
  }
  
  .news .comments .comments-count {
    font-weight: bold;
  }
  
  .news .comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .news .comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .news .comments .comment .comment-img img {
    width: 60px;
  }
  
  .news .comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .news .comments .comment h5 a {
    font-weight: bold;
    color: var(--color-default);
    transition: 0.3s;
  }
  
  .news .comments .comment h5 a:hover {
    color: var(--color-primary);
  }
  
  .news .comments .comment h5 .reply {
    padding-left: 10px;
    color: var(--color-secondary);
  }
  
  .news .comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .news .comments .comment time {
    display: block;
    font-size: 14px;
    color: rgba(82, 86, 94, 0.8);
    margin-bottom: 5px;
  }
  
  .news .comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .news .comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .news .comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .news .comments .reply-form p {
    font-size: 14px;
  }
  
  .news .comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .news .comments .reply-form input:focus {
    box-shadow: none;
    border-color: rgba(254, 185, 0, 0.8);
  }
  
  .news .comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .news .comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: rgba(254, 185, 0, 0.8);
  }
  
  .news .comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .news .comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: var(--color-secondary);
  }
  
  .news .comments .reply-form .btn-primary:hover {
    color: var(--color-secondary);
    background-color: var(--color-primary);
  }
  
.carousel-control-next-icon:after {
    display: none;
}

.carousel-control-prev-icon:after {
    display: none !important;
}








.swiper {
    width: 100%;
    height: 100%;
}
/* 
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    
    display: flex;
    justify-content: center;
    align-items: center;
} */
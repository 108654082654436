.stat_warp .serv_wrap {
  border-right: 1px solid rgb(29, 105, 29);
  border-bottom: 2px solid #00D094;
  border-radius: 10px;
  padding: 15px;
  background-color: #f0f0f0;

}

.serv_wrap {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}


.stat_warp .serv_wrap:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: rotate(2deg);
}

.serv_wrap p{
      font-size: 17px !important;
} 